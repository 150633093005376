.tiny_loading {
	position: relative;
	text-align: center;
	padding:1em 0;
	box-sizing:border-box;
	height:3em;
	div {
		position: absolute;
		display: inline-block;
		width: 1em;
		height: 1em;
		background-color: #999;
		border-radius: 100%;
		animation: go-and-return 1.5s infinite linear;
	}
  
  	.circle2 {
		animation-delay: -0.6s;
  	}
  
	.circle3 {
		animation-delay: -0.3s;
  	}
  
	@keyframes go-and-return {
		0%,
		100% {
			transform: translateX(0);
		}
		25% {
			transform: translateX(-30px);
		}
		75% {
			transform: translateX(30px);
		}
	  }
}
$light_primary_color: #86c6b6;
$primary_color: #75b5a5;
$dark_primary_color: #64a494;
$light_secondary_color: #d17d7c;
$secondary_color: #c06c6b;
$dark_secondary_color: #a95b5a;
$border_color: #52788d;
$ok_color: #428A34;
$ng_color: #CC3333;
$important_color: #FF3333;
//$text_color: #403B2A;
$text_color: #303030;
$light_text_color: #606060;
//$timeline_bg_color: #DDDDDD;
$timeline_bg_color: #d1dae1;

$guu_color: #663333;
$choki_color: #336633;
$paa_color: #333366;


* {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-box-shadow: none;
	box-shadow: none;	 
	outline: none;	
}

html {
	background-color:#F7F7F8;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	padding-top:3em;
}

.root {
	padding:1em;
	padding-top: 4.5em;
	height:100%;
	box-sizing:border-box;
	position:relative;
	line-height: 1.5em;
	overflow-y:auto;
}

.miniRoot {
	padding:1em;
	padding-top: 1.5em;
	width: 100%;
	max-width:480px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}

.smallRoot {
	padding:1em 0;
	padding-top: 0em;
	width: 100%;
	max-width:600px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}

.mediumRoot {
	padding:0em;
	width: 100%;
	max-width:960px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}

.modal {
	position:absolute;
	width:100%;
	max-width:940px;
	max-height:100%;
	box-sizing:border-box;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
}

.modalHead {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding:0em;
	padding-top:0.1em;
	line-height:1em;
	box-sizing:border-box;
	background-color:#393F4F;
	z-index:999;
}

.modalSmall {
	max-width:320px;
}

.modalMedium {
	max-width:600px;
}

.modalRoot {
	line-height: 1.4em;
	overflow-x:hidden;
	overflow-y:auto;
}

.alert {
	position:absolute;
	width:100%;
	max-width:240px;
	background-color:#333333;
	color: #000000;
	box-sizing:border-box;
}

.textarea {
	background-color:white;
}

.btnWhite {
	background-color:white!important;
}


div.nextBtns {
	text-align:center;
	margin-top:1em;
}

.withIcon {
	display:inline-flex;
	vertical-align:middle;
}

.clickable {
	cursor:pointer;
}

.link {
	text-decoration: none;
	color:$text_color;
}

.wide {
	width:100%;
}

.paper {
	padding:1em 1em;
	//padding-bottom:6em;
	background-color:white;
	line-height:1.8em;
	font-size:90%;
}

.noPad {
	padding:0;
}

.modalPaper {
	padding-top:3em;
	padding-bottom:3em;
	h2 {
		color:$primary_color;
		margin:0;
		padding:0.5em 0;
		margin-bottom:1em;
		border-bottom:1px dashed $primary_color;
		position:relative;
		div.btns {
			position:absolute;
			top:0.1em;
			right:0;
		}
	}
	.notice {
		margin-top:5em;
		padding:1em;
		background-color:#F5F5F5;
		h3 {
			font-size:100%;
			font-weight:normal;
			margin:0;
			padding:0;
			margin-bottom:1em;
			//color:$primary_color;
		}
	}
}

.modalPaperNoHeader {
	padding-top:1em;
}

.paperTitle {
	margin-bottom:1em;
}

.section {
	margin-bottom:2em;
	p {
		margin-bottom:1em;
	}
}
.center {
	text-align:center;
}

.formControl {
	margin-bottom:3em!important;
	.formLabel {
		font-weight:bold!important;
	}
	.formValue {
		margin-top:0.25em!important;
		color:$text_color;
	}
}
.formControlDense {
	margin-bottom:0em!important;
}
.formName {
	font-weight:bold;
	padding-top:1.2em!important;
	color:$dark_secondary_color;
}
#login {
	.mailLoginInput {
		border:1px solid $border_color;
		border-radius:0.5em;
		padding:1em;
		margin-top:1em;
	}
}

.popperAnswer {
	border:1px solid $border_color;
	background-color:white;
	padding:0.5em 1em;
	filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.6));
	max-width:240px;
	z-index:9999;
}

.drawer {
	width:260px;
	padding:1em;
}

.faces {
	.imgFace {
		text-align:center;
		height:140px;
		img {
			max-width:100px;
		}
	}
	.FaceName {
		text-align:center;
	}
}

.roomTitle {
	margin-bottom:2em;
	p {
		margin-bottom:1em;
	}
}

.more {
	text-align:center;
	margin-top:2em;
	margin-bottom:4em;
}

#morning_news {
	h2 {
		text-align:center;
		font-size:200%;
		margin-bottom:1em;
	}

	div.morningAlives {
		div.place {
			border:1px solid $border_color;
			border-radius:0.5em;
			padding:1em;
			padding-bottom:0;
			height:100%;
			box-sizing:border-box;
			div.placeName {
				text-align:center;
				padding-bottom:0.5em;
				margin-bottom:1em;
				font-size:150%;
				font-weight:bold;
				//border-bottom:1px solid silver;
				color:$primary_color;
			}
			div.chara {
				margin:0 auto;
				margin-bottom:2em;
				position:relative;
				width:100px;
				div.name {
					text-align:center;
					font-size:80%;
				}
				div.item {
					position:absolute;
					top:-0.5em;
					left:-0.5em;
					background-color:$primary_color;
					color:white;
					width:1.5em;
					height:1.5em;
					border-radius:1em;
					line-height:1em;
					font-size:120%;
					box-sizing:border-box;
					padding-top:0.15em;
					padding-left:0.3em;
				}
				div.guu {
					color:white;
					background-color:$guu_color;
					padding-left:0.35em;
				}
				div.choki {
					color:white;
					background-color:$choki_color;
					padding-left:0.25em;
				}
				div.paa {
					color:white;
					padding-top:0.2em;
					background-color:$paa_color;
				}
				div.batsu {
					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%, -60%);
					font-size:400%;
					color:#000000;
				}
			}
			div.dead {
				opacity:0.4;
			}
			div.trap {
				width:100px;
				height:156px;
				div.name {
					position:absolute;
					bottom:0;
					left:50%;
					transform:translate(-50%, 0);
					white-space:nowrap;
					width:100%;
				}
				div.figure {
					font-size:600%;
					position:absolute;
					top:40%;
					left:50%;
					transform:translate(-50%, -50%);
				}
			}
		}
	}
	div.morningEmptys {
		text-align:center;
		margin-top:5em;
		p {
			margin:0;
			padding:0;
		}
		div.count {
			color:$primary_color;
			margin-bottom:1em;
		}
	}
	div.morningDeads {
		text-align:center;
		margin-top:5em;
		div.count {
			margin-bottom:2em;
			//color:$primary_color;
		}
	}
	div.noDead {
		text-align:center;
		margin-top:5em;
		color:#333333;
	}
	div.goNext {
		text-align:center;
		margin-top:6em;
		.btn {
			padding-right:1em;
		}
	}
	div.memberResultList {
		margin-bottom:4em;
		div.member {
			position:relative;
			height:133px;
			margin-bottom:2em;
			div.face {
				position:absolute;
				top:0;
				left:0;
			}
			div.name {
				font-size:90%;
				position:absolute;
				bottom:0.5em;
				right:1em;
			}
			div.result {
				padding-top:2em;
				margin-left:105px;
				height:133px;
				box-sizing:border-box;
				border-radius:0.5em;
				line-height:1em;
				text-align:center;
				border:1px solid silver;
				p {
					margin:0;
					padding:0;
					font-size:120%;
				}
				p.item {
					margin-bottom:0.5em;
					font-size:90%;
				}
				p.vanish {
					color:gray;
				}
				p.trap {
					color:$secondary_color;
					span {
						font-size:60%;
						//color:$text_color;
					}
				}
				p.alive {
					color:$primary_color;
					font-size:150%;
					margin-top:0.5em;
				}
				p.icon {
					position:absolute;
					top:0.25em;
					right:0.25em;
					color:gray;
				}
				p.iconTrap {
					color:$secondary_color;
				}
			}
		}

	}
	h2.resultDraw {
		font-size:180%;
		color:gray;
		border-color:gray;
	}
	h2.resultGhost {
		font-size:180%;
		color:$secondary_color;
		border-color:$secondary_color;
	}
	h2.resultWin {
		font-size:180%;
	}
	.roomTimeline {
		max-width:600px;
		margin:0 auto;
		padding:0;
		background-color:white;
		border-radius:0 0 0 0;
		.resultMessage {
			text-align:center;
			///color:gray;
			margin-bottom:3em;
		}
		.goDown {
			text-align:center;
			font-size:500%;
			color:gray;
			margin-bottom:1em;
		}
	}
}

.loadingTop {
	position:relative;
	margin-bottom:2em;
	.btnNews {
		position:absolute;
		top:0.5em;
		right:0;
		background-color:white!important;
	}
}

div.roomData {
	h2 {
		font-size:100%;
		color:$dark_primary_color;
		border-bottom:1px solid $border_color;
	}
	h2.notice {
		color:$primary_color;
		border-color:$primary_color;
	}
	div.roomStatus {
		margin-bottom:3em;
		line-height:1.5em;
		div.title {
			font-size:130%;
			color:$primary_color;
			line-height:1.4em;
		}
		div.status {
			color:gray;
			font-size:90%;
		}
		div.next {
			span {
				margin-right:0.5em;
				//background-color:$secondary_color;
				background-color:gray;
				padding:0 0.5em;
				color:white;
			}
			font-size:90%;
		}
	}
	div.entry {
		margin-bottom:3em;	
	}
	div.please {
		padding:0.5em 1em;
		margin-bottom:3em;
		background-color:#EEEEEE;
		font-size:80%;
		text-align:center;	
	}
	div.myChara {
		padding:0;
		margin-bottom:3em;
		div.face {
			background-color:#EEEEEE;
			padding:0.3em 0;
			//margin-bottom:0.5em;
		}
		div.vanished {
			text-align:center;
			background-color:$secondary_color;
			color:white;
			padding:0;
		}
		div.blessed {
			text-align:center;
			background-color:$light_primary_color;
			color:white;
			padding:0;
		}
	}
	div.next {
		margin-bottom:3em;
		.notice {
			color:$primary_color;
			margin-right:0.5em;
		}
		div.selectedNext {
			text-align:center;
			background-color:#FFEEE0;
			padding:0.5em 0;
		}
		div.selectedTrap {
			text-align:center;
			background-color:#F5F5F5;
			padding:0.5em 0;
		}
		div.noaction {
			text-align:center;
			background-color:#EEEEEE;
			padding:0.5em 0;
			line-height:1em;
			span {
				font-size:80%;
				color:#999999;
			}
		}
	}
	div.memberList {
		ol {
			margin:0;
			padding:0;
			padding-left:1.5em;
			li.dead {
				color:#CCCCCC;
				.ghost {
					//color:#FFAA99;
					color:$secondary_color;
					margin-left:0.5em;
				}
			}
		}
	}
}

.roomTimeline {
	padding:1em;
	background-color:$timeline_bg_color;
	border-radius:0 0 5px 0;
	div.roomDays {
		background-color:#F5F5F5;
		border-bottom:1px solid #EEEEEE;
		border-right:1px solid #EEEEEE;
	}
	div.entry {
		margin-top:2px;
	}
	div.please {
		padding:0.5em 1em;
		background-color:#EEEEEE;
		font-size:80%;
		text-align:center;	
		margin-top:2px;
	}
	div.talks {
		//background-color:#DDDDDD;
		margin-top:1em;
		//padding:1em;
		div.talk {
			margin-bottom:2em;
			position:relative;
			div.face {
				position:absolute;
				top:1em;
				left:0;
				width:100px;
				height:133px;
				text-align:left;
			}
			div.text {
				h3 {
					font-weight:normal;
					font-size:80%;
					color:#333333;
					position:absolute;
					bottom:0em;
					right:0.75em;
					margin:0;
					padding:0;
				}
				.iconPrivate {
					//color:$dark_primary_color;
					color:$secondary_color;
				}
				.iconGhost {
					color:$secondary_color;
				}
				position:relative;
				margin-left:110px;
				min-height:133px;
				background-color:white;
				border-radius:0.25em;
				padding:0.75em;
				padding-bottom:1.75em;
				box-sizing:border-box;
				border:1px solid $border_color;
				word-break: break-all;
				word-wrap: break-word;
				overflow-wrap: break-word;
			}
			div.serial {
				height:1em;
				line-height:0;
				color:$text_color;
				text-align:right;
				position:relative;
				span.date {
					color:$light_text_color;
				}
				span.no {
					position:absolute;
					top:0;
					left:0;
				}
				span.mute {
					position:absolute;
					top:0;
					left:6em;
				}
			}
			div.textPrivate {
				background-color:#666666;
				color:white;
				border-width:0;
				h3 {
					color:white;
				}
			}
			div.textGhost {
				background-color:#F5EEEE;
				border-width:0;
				color:#333333;
				h3 {
					color:#666666;
				}
			}
		}
	}
	div.sysTalk {
		//padding-left:110px;
		padding-left:40px;
		margin-bottom:1em;
		span.type {
			background-color:$dark_secondary_color;
			//background-color:#666666;
			border-radius:0.5em;
			color:white;
			padding:0 1em;
			margin-right:0.5em;
		}
		span.detail {
			color:$text_color;
			b {
				font-weight:normal;
				color:black;
			}
		}
		span.time {
			color:$light_text_color;
			margin-left:1em;
		}
	}
}

.addRoom {
	position:relative;
	border:1px solid $border_color;
	border-radius:0.5em;
	padding:1em 12px;
	height:160px;
	box-sizing:border-box;
	text-align:center;
	div.guide {
		margin-bottom:1em;
	}
	div.hint {
		font-size:80%;
		color:#666666;
		text-align:left;
		margin-top:1.8em;
		padding:0.4em 1em;
		line-height:1.2em;
		background-color:#F5F5F5;
	}
}

.topRoom {
	position:relative;
	border:1px solid gray;
	border-radius:0.5em;
	padding:12px;
	height:160px;
	box-sizing:border-box;
	div.face {
		position:absolute;
		top:12px;
		left:12px;
	}
	div.text {
		position:relative;
		margin-left:110px;
		line-height:1em;
		height:133px;
		div.title {
			color:$primary_color;
			font-size:120%;
			line-height:1.1em;
		}
		div.days {
			position:absolute;
			//margin-bottom:0.5em;
			top:66px;
			//margin-left:110px;
			font-size:90%;
			span.accepting {
				background-color:$important_color;
				color:white;
				//border-radius:0.5em;
				padding:0 0.5em;
				margin-left:1em;
			}
			.nowplaying {
				color: $important_color;
			}			
		}
		div.nexttime {
			position:absolute;
			//margin-bottom:0.5em;
			top:86px;
			color:#666666;
			font-size:80%;
			span {
				margin-right:1em;
			}
		}
		div.data {
			position:absolute;
			bottom:0;
			background-color:#F5F5F5;
			padding:0.5em 1em;
			font-size:90%;
			color:#333333;
			box-sizing:border-box;
			width:100%;
		}
	}
	div.members {
		position:absolute;
		top:126px;
		left:66px;
		width:46px;
		background-color:$dark_primary_color;
		line-height:1em;
		padding:2px 0;
		text-align:center;
		border-radius:0.5em 0 0.3em 0;
		color:white;
	}
}

.greeting {
	margin-top:6em;
	text-align:center;
	div.reason {
		width:240px;
		text-align:left;
		margin:1em auto;
		margin-bottom:2em;
		padding:1em;
		border-top:1px solid $light_primary_color;
		border-bottom:1px solid $light_primary_color;
	}
	div.copyright {
		margin-top:2em;
		margin-bottom:6em;
		a {
			color:black;
		}
	}
}

.verBox {
	text-align:center;
	border:1px solid $border_color;
	padding-bottom:3em;
	div.current {
		font-size:300%;
		color:$dark_secondary_color;
	}
	div.latest {
		font-size:300%;
		color: $primary_color;
	}
}

.verGuide {
	margin-bottom:3em;
	font-size:90%;
	h3 {
		color:$primary_color;
	}
}

div.help {
	margin-bottom:6em;
	h3 {
		color:gray;
		margin:0;
		margin-bottom:0.5em;
		font-size:120%;
		color:$primary_color;
	}
	div.text {
		margin-bottom:4em;
		p {
			margin:0;
			padding:0;
			margin-bottom:0.2em;
		}
		ul {
			margin:0;
			margin-top:1em;
		}
		div.hint {
			background-color:#FFFAF2;
			padding:1em;
			font-size:80%;
			margin:1em 0;
			margin-top:2em;
		}
	}
}

.message {
	position:relative;
	div.counter {
		position:absolute;
		bottom:0.7em;
		right:0em;
		font-size:100%;
		color:$primary_color;
		background-color:white;
		line-height:1em;
		padding:0.1em 0.5em;
	}
	div.zero {
		color:$text_color;
	}
	div.over {
		color:$important_color;
	}
}

.guu {
	color:$guu_color;
}
.choki {
	color:$choki_color;
}
.paa {
	color:$paa_color;
}

$primary_color: #86c6b6;
$text_color: #FFF2CA;
$secondary_color: #d1bdbc;
.appbar {
    //background-color:#B2DFDB!important;
    background-color:$primary_color!important;
	.personMenu {
		h3 {
			font-size:100%;
			margin:0;
			padding:0;
			margin-bottom:0.5em;
		}
	}
	.personData {
		list-style:none;
		margin:0;
		padding:0;
		margin-bottom:2em;
		li {
			margin:0;
			padding:0;	
		}
	}
}

.toolbar {
	position:"relative";
	text-align:center;
	.personButton {
        position:absolute;
		right:0.5em;
        padding-right:0!important;
        padding-left:0!important;
		color: $text_color!important;
	}
	.menuButton {
        text-align:left;
        position:absolute;
		left:0.5em;
        padding-right:0!important;
        padding-left:0!important;
		color: $text_color!important;
	}
	.loginButton {
		background-color: $text_color!important;
		padding-right:0.5em;
		padding-left:0.5em;
		color:$primary_color;
	}
	.logo {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
	}
}
